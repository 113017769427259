.section1DTcontainer{
    display: flex;
    flex-direction: column;
    align-items: center;
}


.section1DT{
    /* width: 100%; */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 44px;
    gap: 68px;
}

.section1DTdots1{
    height: 152px;
    width: 231px;
}

.section1DTText{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 440px;
    gap: 20px;
}

.section1DTTitle{
    font-weight: 600;
    font-size: 48px;
}

.section1DTSubtext{
    font-weight: 400;
    font-size: 16px;
    color: #00000080;
}

.section1DTdots2{
    margin-top: 28px;
    height: 152px;
    width: 230px;
}



.section2DT
{
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.section2DTtile{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    padding: 24px;
    flex: 50%;

    border-width: 2px;
    border-style: solid;
    border-radius: 12px;
    border-color: #fafafa;
}

.section2DTtitle
{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
}


.section2DTIcon{
    height: 48px;
    width: 48px;
}

.section2DTTitle{
    font-weight: 600;
    font-size: 24px;
}

.section2DTSubText{
    font-weight: 400;
    font-size: 16px;
    max-width: 467px;
    text-align: left;
    color: #00000080;
}

.DTline{
    margin-top: 50px;
    background-color: #EBEBEB;
    width: 100%;
    height: 1px;
}




.section3DT{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    width: 90%;
}


.section3DTsort{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
}

.section3DTtitle{
    font-weight: 500;
    font-size: 16px;

}

.section3DTfilter{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
}

.section3DTleft{
    display: flex;
    flex-direction: row;
    gap: 32px;
}

.section3DTcolumn{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
}


.section3DTpriceRow{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2px;
}


.section3DTinput
{
    height: 48px;
    width: 99px;
    border-radius: 12px;
    border-color: #EBEBEB;
    border-width: 1px;
    border-style: solid;

    text-align: center;
}


.dropdown{
    height: 48px;
    width: 200px;

    padding-left: 10px;
    border-radius: 12px;
    border-color: #EBEBEB;
    border-width: 1px;
    border-style: solid;
}

.section3DTright{
    display: flex;
    flex-direction: row;
    gap: 12px;
}

.section3DTapplyBtn{
    height: 48px;
    width: 125px;
    border-radius: 12px;

    background-color: #01A4BD;
    border-color: transparent;
    color: white;

    font-weight: 500;
    font-size: 16px;
}




.section3DTclearBtn{
    height: 48px;
    width: 143px;
    border-radius: 12px;

    background-color: white;
    border-color: #01A4BD;
    color: #01A4BD;

    font-weight: 500;
    font-size: 16px;
}



.section4DT{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 90%;
}


.section4DTgrid{
    display: grid;
    grid-template-columns: auto auto auto auto;
    gap: 20px;
}

.section4DTbtn{
    height: 48px;
    width: 124px;
    border-radius: 12px;

    background-color: white;
    border-color: #EBEBEB;
    border-width: 1px;
    border-style: solid;

    font-weight: 500;
    font-size: 16px;
    color: #737373;
    cursor: pointer;
    margin-top: 30px;
}


.section5DT{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 110px;
    margin-top: 70px;
    width: 90%;
}

.section5DTtext{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.section5DTtitle{
    font-weight: 600;
    font-size: 40px;
    color: black;
    text-align: left;
    width: 373px;
}
.section5DTblue{
    font-weight: 600;
    font-size: 40px;
    color: #01A4BD;
    text-align: left;
    width: 373px;
}
.section5DTsubtext{
    font-weight: 400;
    font-size: 16px;
    color: #00000080;
    text-align: left;
    width: 278px;

}

.section5DTfaq{

    /* width: 800px; */
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 70%;
}

.faq{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.faqRow{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
}

.faqTitle{
    font-weight: 500;
    font-size: 16px;
}

.faqArrow{
    width: 20px;
    height: 20px;
    transition: all 0.3s;
    
}

.faqArrow.chosen{
    transform:rotate(180deg);
}

.faqSubText{
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    text-align: left;
    margin-top: 30px;
    color: #00000080;
    /* max-width: 700px; */
}

.faqLine{
    height: 1px;
    width: 100%;
    background-color: #EBEBEB;
    border-color: #01A4BD;
}

@media (max-width:900px) {


    .section1DT{display:flex; flex-direction: column; align-items: center;}

    .section1DTdots1{display: none;}
    .section1DTdots2{display: none;}

    .section1DTText{width: 90%;} 

    
    .section3DTfilter{display: none;}

    .section4DTgrid{display: flex; flex-direction: column; align-items: center;}

    .featuredTeacherTile{min-height: 400px;}


    .section2DT{
        flex-direction: column;
    }

    .section2DTtitle{
        flex-direction: row;
    }

    .section2DTTitle{
        text-align: left;
    }

    .section5DT{flex-direction: column;}

    .section5DTfaq{width: 100%;}
    .faqTitle{text-align: left;}
}